import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse } from '~/api/types/Api'

export default class AgreementService extends ApiServiceBase {
  async list(): Promise<ApiResponse<object>> {
    return await this.call('/v1/agreements')
  }
  async accept(id: string, postData: object): Promise<ApiResponse<object>> {
    return await this.call(`/v1/agreements/${id}/accept`, postData)
  }
}
