import type { NewsCategory, NewsPost } from '~/api/types/News'
import type { ApiPaginatedResponse, ApiResponse } from '~/api/types/Api'
import type { FetchOptions } from 'ofetch'
import { ApiServiceBase } from '~/api/services/ApiServiceBase'

export default class NewsService extends ApiServiceBase {
  async newsCategories(): Promise<ApiResponse<NewsCategory[]>> {
    return await this.call('/v1/news-categories')
  }

  async newsPosts(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<NewsPost[]>> {
    return await this.call('/v1/news-posts', options)
  }

  async newsPost(id: string | number): Promise<ApiResponse<NewsPost>> {
    return await this.call(`/v1/news-posts/${id}/?include=newsCategory`)
  }
}
