import type {
  ForgotPasswordRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
  VerifyRequest,
  UserData,
} from '~/api/types/Authentication'
import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { UseFetchOptions } from '#app'
import type { ApiResponse } from '../types/Api'

export default class AuthenticationService extends ApiServiceBase {
  async login(data: LoginRequest): Promise<any> {
    return await this.call('/v1/login', {
      method: 'post',
      body: data,
    })
  }

  async logout(): Promise<any> {
    return await this.call('/v1/logout', {
      method: 'post',
    })
  }

  async register(data: RegisterRequest): Promise<any> {
    return await this.call('/v1/register', {
      method: 'post',
      body: data,
    })
  }

  async forgotPassword(data: ForgotPasswordRequest): Promise<any> {
    return await this.call('/v1/forgot-password', {
      method: 'post',
      body: data,
    })
  }

  async resetPassword(data: ResetPasswordRequest): Promise<{ status: string }> {
    return await this.call('/v1/reset-password', {
      method: 'post',
      body: data,
    })
  }

  async verify(data: VerifyRequest): Promise<any> {
    return await this.call('/v1/user/verify', {
      method: 'post',
      body: data,
    })
  }

  async resendVerify(): Promise<any> {
    return await this.call('/v1/user/verify/resend', {
      method: 'post',
      body: {},
    })
  }

  async resendEmailVerification(): Promise<any> {
    return await this.call('/v1/email/verification-notification', {
      method: 'post',
    })
  }

  async user(options?: UseFetchOptions<'json'>): Promise<ApiResponse<UserData>> {
    return await this.call('/v1/user', options)
  }
}
