export default class ApiError extends Error {
  message: string
  errors: string[]
  statusCode: string

  constructor(data: any) {
    super(data.message)
    this.message = data.message
    this.errors = data.errors
    this.statusCode = data.statusCode
  }
}

export type ApiErrors = {
  [key: string]: string[]
}
