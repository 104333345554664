import PrimeVue from 'primevue/config'
import DatePicker from 'primevue/datepicker'
import Aura from '@primevue/themes/aura'
import { definePreset } from '@primevue/themes'

export default defineNuxtPlugin((nuxtApp) => {
  const MyPreset = definePreset(Aura, {
    semantic: {
      primary: {
        50: '{pink.50}',
        100: '{pink.100}',
        200: '{pink.200}',
        300: '{pink.300}',
        400: '{pink.400}',
        500: '{pink.500}',
        600: '{pink.600}',
        700: '{pink.700}',
        800: '{pink.800}',
        900: '{pink.900}',
        950: '{pink.950}',
      },
    },
  })
  nuxtApp.vueApp.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      options: {
        prefix: 'p',
        darkModeSelector: 'false',
      },
    },
    locale: {
      firstDayOfWeek: 1,
    },
  })

  nuxtApp.vueApp.component('DatePicker', DatePicker)
})
