import Vue3Toastify, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 2000,
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    transition: toast.TRANSITIONS.SLIDE,
    closeButton: false,
  })
  return {
    provide: {
      toast,
    },
  }
})
