import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse } from '~/api/types/Api'

export default class VehicleService extends ApiServiceBase {
  async create(postData: object): Promise<ApiResponse<object>> {
    return await this.call('/v1/vehicle-changes', postData)
  }
  async list(query?: object | null): Promise<ApiResponse<object>> {
    return await this.call('/v1/vehicle-changes?include=documents,documentTypes', query ?? {})
  }
  async delete(id: string, postData: object): Promise<ApiResponse<object>> {
    return await this.call(`/v1/vehicle-changes/${id}`, postData)
  }
  async submit(id: string, postData: object): Promise<ApiResponse<object>> {
    return await this.call(`/v1/vehicle-changes/${id}/submit`, postData)
  }
}
