import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse } from '~/api/types/Api'

export default class InvoiceService extends ApiServiceBase {
  async all(query?: object | null): Promise<ApiResponse<object>> {
    return await this.call('/v1/invoices', query ?? {})
  }
  async get(id: string, query: string | null): Promise<ApiResponse<object>> {
    return await this.call(`/v1/invoices/${id}${query}`)
  }

  // Invoice summary statements
  async getAllInvoiceSummaryStatements(query?: object | null): Promise<ApiResponse<object>> {
    return await this.call('/v1/invoice-summary-statements', query ?? {})
  }

  async getInvoiceSummaryStatement(id: string, query: string | null): Promise<ApiResponse<object>> {
    return await this.call(`/v1/invoice-summary-statements/${id}${query}`)
  }
}
