import type { ApplicationInfo } from '~/api/types/Application'
import type { ApiResponse, ApiPaginatedResponse } from '~/api/types/Api'
import { ApiServiceBase } from '~/api/services/ApiServiceBase'

export default class ApplicationService extends ApiServiceBase {
  async info(): Promise<ApplicationInfo> {
    return await this.call('/')
  }

  async fleets(query?: object | null): Promise<ApiPaginatedResponse<object>> {
    return await this.call('/v1/fleets', query ?? {})
  }

  async regions(): Promise<ApiPaginatedResponse<object>> {
    return await this.call('/v1/regions')
  }

  async driver(query: string | null): Promise<ApiResponse<object>> {
    return await this.call(`/v1/driver${query ? query : ''}`)
  }
}
