import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse } from '~/api/types/Api'

export default class BookingsService extends ApiServiceBase {
  async list(query?: object | null): Promise<ApiResponse<object>> {
    return await this.call('/v1/bookings', query ?? {})
  }
  async get(id: string): Promise<ApiResponse<object>> {
    return await this.call(`/v1/bookings/${id}`)
  }
}
