import { ApiServiceBase } from '~/api/services/ApiServiceBase'
import type { ApiResponse, ApiPaginatedResponse } from '~/api/types/Api'

export default class DocumentService extends ApiServiceBase {
  async all(query: string | null): Promise<ApiPaginatedResponse<object>> {
    return await this.call(`/v1/documents${query}`)
  }
  async current(): Promise<ApiResponse<object>> {
    return await this.call('/v1/documents/current')
  }
  async create(postData: object): Promise<ApiResponse<object>> {
    const uploadDocumentResponse = await this.call('/v1/documents', postData)
    const body = new FormData()
    body.append('subject', 'Document Upload')
    body.append(
      'comment',
      `A new document has been uploaded pending approval. We will respond to this shortly to approve or reject, you will be notified on this ticket and via the portal documents section, thank you.`
    )
    body.append('custom_fields', '[]')
    body.append('tags', 'portal_j')

    await useFetch(`/api/zendesk/tickets`, {
      method: 'POST',
      body,
    })

    return uploadDocumentResponse
  }
}
